<script setup lang="ts">
import type { TheSidebarPropMenus } from '@/components/layout/TheSidebar/types'

const { t } = useI18n()

const menus = ref<TheSidebarPropMenus>([
  {
    title: t('ops.sidebar.dashboard'),
    path: '/ops',
    exact: true,
    event: 'Access OPS Dashboard',
  },
  {
    title: t('ops.sidebar.staffs'),
    path: '/ops/staff',
    event: 'Access OPS Staffs',
  },
  {
    title: t('ops.sidebar.customers'),
    path: '/ops/customers',
    event: 'Access OPS Customers',
  },
  {
    title: t('mealPlans.meta.title'),
    path: '/ops/meal-plans',
    event: 'Access OPS Meal Plans',
  },
])

menus.value.push({
  title: t('plans.meta.title'),
  path: '/ops/plans',
  event: 'Access OPS Plans',
})
</script>

<template>
  <div class="flex">
    <TheSidebar class="top-0 z-10 hidden h-screen md:fixed md:flex" :menus="menus" />
    <TheNavbar class="fixed left-0 right-0 z-10" />
    <TheTabBar class="md:hidden" :menus="menus" />
    <main
      class="mb-[56px] mt-[60px] max-w-full flex-1 transform-gpu p-2 transition-all will-change-transform md:ml-[240px] md:max-w-[calc(100%-240px)]"
    >
      <slot />
    </main>
  </div>
</template>
